import { Col, Container, Row } from "reactstrap"
import NavbarComp from "../../../../Components/Navbar"
import { FaAngleLeft } from "react-icons/fa";
import Masthead from "../../../../Components/Masthead";


const AllBookinngDetails = ({data, step}) => {
    console.log(data.dates.split(','))
    return(
        <>
         <Container>
            <Row>
                <Col md="12">
                    <div className="navbar">
                    <Masthead />
                    </div>
                    
                   
                </Col>
                <div className="booking-info-container">
                <div className="back" style={{marginTop:"0"}}>
                        <span
                            onClick={() => step(0)}
                            style={{ cursor: "pointer",display:"flex",alignItems:"center"}}
                        >
                         <FaAngleLeft /> Back
                        </span>
                    </div>
                    <h2 className="text-center w-100">Booking Information</h2>
                </div>
                <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Service Type</label>
                    <p className="dogform-text">{data.service_type || "-"}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">No. of dogs</label>
                    <p className="dogform-text">{data?.dogs?.length || "-"}</p>
                </Col>

                <Col md="3">
                    <label className="dogform-label">Dates</label>
                    {!data.service_type == 'Daycare' ? <p className="dogform-text">{data.dates || "-"}</p> 
                    :
                    <>
                    <br></br>
                    {data.dates.split(',').map((item) => {
                        return(
                            <p style={{backgroundColor: '#dadada7a', borderRadius: '3px', padding: '0px 6px', margin: '6px 6px 0px 0px'}}>{item}</p>
                        )
                    })}
                    </>}
                </Col>
                </Row>

                <Row className="mx-5 mt-5">
                    <h5 >Possible Booking Locations</h5>
                    {data.location_type && data.location_type.includes('sitter_location') && <div xs={12} sx={{mt:2}}>
                        <p><span>Sitter's Home</span></p>
                    </div>}
                    {data.location_type && data.location_type.includes('parent_location') && <div xs={12} sx={{mt:2}}>
                        <p><span>Parent's Home : </span>
                        <span>{data?.parent_address && data.parent_address.city}</span>
                        </p>
                    </div>}
                            {data.location_type && data.location_type.includes('other_address') &&  <div xs={12} sx={{mt:2}}>
                        <p><span>Parent's Home : </span>{data?.manual_address &&  data?.manual_address?.city }</p>
                    </div>}
                </Row>
            </Row>
        </Container>
        </>
    )
}

export default AllBookinngDetails