import { useEffect, } from 'react'
import { useNavigate } from 'react-router-dom'

const DecisionComponent = () => {
  const navigate = useNavigate()
  const isAuthorised = localStorage.getItem('token')
  const isKycDocsUploaded = localStorage.getItem('isKycDocsUploaded')
  useEffect(() => {
    const pathName = window.location.pathname

    if(isAuthorised){
      if(isKycDocsUploaded == true || isKycDocsUploaded == "true"){
        if(pathName.includes('/booking/information/')) {
          navigate(pathName)
        }else{
          navigate('/sitter/home')
        }
      }else{
        navigate('/bookingpreferences')
      }
    }else{
      navigate('/applicationstart')
    }
  }, [])

  return null
}

export default DecisionComponent